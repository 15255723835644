import { Card, CardContent, CardHeader } from "@/components/ui/card";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

const NotFound: React.FC = () => {
  return (
    <Card>
      <CardHeader>404 - Page Not Found</CardHeader>
      <CardContent>
        <p>Sorry, the page you are looking for does not exist.</p>
        <Button>
          <Link to="/">Go Home</Link>
        </Button>
      </CardContent>
    </Card>
  );
};

export default NotFound;
