import React, { useState } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const [showStackTrace, setShowStackTrace] = useState(false);

  return (
    <Card className="p-6 text-center bg-red-100 text-red-700 border border-red-400 rounded shadow-lg">
      <CardHeader>
        <CardTitle className="text-3xl font-semibold mb-4">
          Something went wrong.
        </CardTitle>
        <CardDescription className="mb-4">{error.message}</CardDescription>
      </CardHeader>
      <CardContent>
        <Button className="mr-4" variant="default" onClick={resetErrorBoundary}>
          Retry
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowStackTrace(!showStackTrace)}
        >
          {showStackTrace ? "Hide Details" : "Show Details"}
        </Button>
        {showStackTrace && (
          <pre className="mt-4 p-4 bg-gray-100 text-left text-gray-800 rounded overflow-auto">
            {error.stack}
          </pre>
        )}
      </CardContent>
    </Card>
  );
};

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset any state or perform any action needed to recover from the error
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
