import React from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

const GoogleWorkspacesConfigurationPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-4">
            Google Workspaces Configuration
          </h1>
        </CardHeader>
        <CardContent>
          <p className="leading-7 mb-4">
            Configure your Google Workspaces integration settings.
          </p>
          <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
            <Label htmlFor="client-id">Client ID</Label>
            <Input type="text" id="client-id" placeholder="Client ID" />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
            <Label htmlFor="client-secret">Client Secret</Label>
            <Input
              type="password"
              id="client-secret"
              placeholder="Client Secret"
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
            <Label htmlFor="redirect-uri">Redirect URI</Label>
            <Input type="text" id="redirect-uri" placeholder="Redirect URI" />
          </div>
          <Button variant="default" color="primary">
            Save Google Workspaces Configuration
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default GoogleWorkspacesConfigurationPage;
