import { columns } from "./columns";
import { DataTable } from "./data-table";
import useSWR from "@/lib/api";

import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import type { Identity } from "./columns";

export default function IdentitiesPage() {
  const { data, error, isLoading } = useSWR<Identity[]>(
    "/tenant/00000000-0000-0000-0000-000000000000/identities"
  );
  if (error) {
    return <div>Error loading identities</div>;
  }

  if (isLoading || !data) {
    return <LoadingSkeleton />;
  }
  return (
    <div className="w-full">
      <div className="rounded-md border">
        <DataTable columns={columns} data={data} isLoading={isLoading} />
      </div>
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Skeleton className="w-[250px] h-[40px] rounded-md" />
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {columns.map((_, index) => (
                <TableHead key={index}>
                  <Skeleton className="w-[100px] h-[20px] rounded-md" />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {Array.from({ length: 5 }).map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((_, colIndex) => (
                  <TableCell key={colIndex}>
                    <Skeleton className="w-[100px] h-[20px] rounded-md" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
