import * as React from "react";
import { Input } from "@/components/ui/input";

interface EditableLabelProps {
  /**
   * The initial text to display in the label.
   */
  initialValue: string;

  /**
   * A callback function to call when the text is changed.
   */
  onChange?: (value: string) => void;

  /**
   * A callback function to call when the label is double-clicked.
   */
  onDoubleClick?: () => void;

  /**
   * A callback function to call when the input is blurred (e.g., when the user clicks or tabs away).
   */
  onBlur?: () => void;

  /**
   * Whether the label should be editable.
   */
  editable?: boolean;

  /**
   * The placeholder text to display in the input field.
   */
  placeholder?: string;

  className?: string;
}

const EditableLabel: React.FC<EditableLabelProps> = ({
  initialValue,
  onChange,
  onDoubleClick,
  onBlur,
  className,
  editable = true,
  placeholder,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [text, setText] = React.useState(initialValue);

  const handleDoubleClick = () => {
    setIsEditing(true);
    onDoubleClick?.();
  };

  const handleBlur = () => {
    setIsEditing(false);
    onBlur?.();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    onChange?.(event.target.value);
  };

  return (
    <div className={className}>
      {isEditing && editable ? (
        <Input
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          placeholder={placeholder}
        />
      ) : (
        <span onDoubleClick={handleDoubleClick}>{text}</span>
      )}
    </div>
  );
};

export default EditableLabel;
