import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";

export function NavItem({
  href,
  label,
  children,
}: {
  href: string;
  label: string;
  children: React.ReactNode;
}) {
  const location = useLocation();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={href}
          className={clsx(
            "flex h-9 w-full items-center justify-start gap-2 rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-full",
            {
              "bg-accent text-black": location.pathname === href,
            }
          )}
        >
          {children}
          <span>{label}</span>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{label}</TooltipContent>
    </Tooltip>
  );
}
