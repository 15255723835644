import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { NavItem } from "@/components/navitem";
import {
  Home,
  Settings,
  Blocks,
  UserRound,
  Boxes,
  KeyRound,
} from "lucide-react";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export function DesktopNav() {
  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-48 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-start gap-4 px-4 sm:py-5">
        <Link
          to="#"
          className="group flex h-9 w-full shrink-0 items-center justify-start gap-2 rounded-full text-lg font-semibold text-primary-foreground md:h-8 md:w-full md:text-base"
        >
          <div className="h-8 w-8 rounded-full bg-gray-300"></div>
        </Link>

        <NavItem href="/dashboard" label="Dashboard">
          <Home className="h-5 w-5" />
        </NavItem>
        <NavItem href="/integrations" label="Integrations">
          <Blocks className="h-5 w-5" />
        </NavItem>
        <NavItem href="/identities" label="Identities">
          <UserRound className="h-5 w-5" />
        </NavItem>

        <NavItem href="/policies/simulator" label="Policy Simulator">
          <Boxes className="h-5 w-5" />
        </NavItem>

        <NavItem href="/accessrequests" label="Access Requests">
          <KeyRound className="h-5 w-5" />
        </NavItem>
      </nav>
      <nav className="mt-auto flex flex-col items-start gap-4 px-4 sm:py-5">
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="#"
              className="flex h-9 w-full items-center justify-start rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-full"
            >
              <Settings className="h-5 w-5" />
              <span className="sr-only">Settings</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Settings</TooltipContent>
        </Tooltip>
      </nav>
    </aside>
  );
}

const routes = [
  { path: "/", breadcrumb: "Home" },
  { path: "/dashboard", breadcrumb: "Dashboard" },
  // { path: "/integrations", breadcrumb: "Integrations" },
  // Add more routes as needed
];

const findBreadcrumb = (pathname: string) => {
  const route = routes.find((route) => route.path === pathname);
  if (route) {
    return route.breadcrumb;
  }
  const formattedPathname = pathname.replace("/", "");
  return formattedPathname.charAt(0).toUpperCase() + formattedPathname.slice(1);
};

export const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to="/">Home</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {pathnames.map((_, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return (
            <React.Fragment key={to}>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                {isLast ? (
                  <BreadcrumbPage>{findBreadcrumb(to)}</BreadcrumbPage>
                ) : (
                  <BreadcrumbLink asChild>
                    <Link to={to}>{findBreadcrumb(to)}</Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
