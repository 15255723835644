import { Outlet, useNavigate } from "react-router-dom";
import { ClerkProvider, SignedIn, SignedOut, SignIn } from "@clerk/clerk-react";

import { Providers, ThemeProvider } from "@/components/providers";
import { User } from "@/components/user";
import { DesktopNav, Breadcrumbs } from "./dashboard";
import { Toaster } from "@/components/ui/sonner";
import ErrorBoundary from "@/components/error";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

export default function RootLayout() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={PUBLISHABLE_KEY}
    >
      <ThemeProvider
        // attribute="class"
        defaultTheme="light"
        enableSystem
        disableTransitionOnChange
      >
        <main className="bg-muted/40">
          <ErrorBoundary>
            <SignedIn>
              <Providers>
                <DesktopNav />
                <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-48">
                  <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
                    <Breadcrumbs />
                    <div className="ml-auto">
                      <User />
                    </div>
                  </header>
                  <main className="grid flex-1 items-start gap-2 p-4 sm:px-6 sm:py-0 md:gap-4 bg-muted/40">
                    <ErrorBoundary>
                      <Outlet />
                    </ErrorBoundary>
                  </main>
                </div>
              </Providers>
            </SignedIn>
            <SignedOut>
              <div className="flex items-center justify-center min-h-screen">
                <SignIn />
              </div>
            </SignedOut>
          </ErrorBoundary>
          <Toaster />
        </main>
      </ThemeProvider>
    </ClerkProvider>
  );
}
