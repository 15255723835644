import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Import the layouts
import RootLayout from "./layouts/root";

// Import the components
import IndexPage from "./routes";
import DashboardPage from "./routes/dashboard";
import Integrations from "./routes/integrations";
import NotFound from "./routes/notfound";
import AWSConfigurationPage from "./routes/integrations/aws";
import GoogleWorkspacesConfigurationPage from "./routes/integrations/google";
import Identities from "./routes/identities/index";
import PolicySimulator from "./routes/policysimulator";
import AccessRequestsPage from "./routes/accessrequests";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
      { element: <IndexPage />, path: "/" },
      { element: <DashboardPage />, path: "/dashboard" },
      { path: "/integrations", element: <Integrations /> },
      { path: "/integrations/aws", element: <AWSConfigurationPage /> },
      {
        path: "/integrations/google-workspaces",
        element: <GoogleWorkspacesConfigurationPage />,
      },
      { path: "/identities", element: <Identities /> },
      { path: "/policies/simulator", element: <PolicySimulator /> },
      { path: "/accessrequests", element: <AccessRequestsPage /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
