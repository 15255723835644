import React from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

const AWSConfigurationPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Card className="w-full max-w-md">
        <CardHeader>
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl mb-4">
            AWS Configuration
          </h1>
        </CardHeader>
        <CardContent>
          <p className="leading-7 mb-4">
            Configure your AWS integration settings.
          </p>
          <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
            <Label htmlFor="access-key-id">Access Key ID</Label>
            <Input type="text" id="access-key-id" placeholder="Access Key ID" />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
            <Label htmlFor="secret-access-key">Secret Access Key</Label>
            <Input
              type="password"
              id="secret-access-key"
              placeholder="Secret Access Key"
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
            <Label htmlFor="region">Region</Label>
            <Input type="text" id="region" placeholder="Region" />
          </div>
          <Button variant="default" color="primary">
            Save AWS Configuration
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AWSConfigurationPage;
