import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";
import { Box } from "lucide-react";

const integrations = [
  {
    name: "AWS",
    description: "Amazon Web Services integration",
    logo: <Box size={40} />,
    link: "/integrations/aws",
  },
  {
    name: "Google Workspaces",
    description: "Google Workspaces integration",
    logo: <Box size={40} />,
    link: "/integrations/google-workspaces",
  },
];

const Integrations: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Integrations</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {integrations.map((integration) => (
          <Link
            to={integration.link}
            key={integration.name}
            className="no-underline"
          >
            <Card className="border rounded-lg p-4 shadow-lg flex items-center">
              {integration.logo}
              <CardContent>
                <h2 className="text-xl font-semibold">{integration.name}</h2>
                <p className="text-gray-600">{integration.description}</p>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Integrations;
